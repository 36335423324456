import authReducer from './reducers/auth.Reducer';
import webConfigReducer from './reducers/webConfig.Reducer';
import menuTypeServiceReducer from './reducers/menuTypeService.Reducer';
import productsReducer from '../redux-store/reducers/products.Reducer';
import orderReducer from '../redux-store/reducers/order.Reducer'

export default {
    authReducer,
    webConfigReducer,
    menuTypeServiceReducer,
    productsReducer,
    orderReducer
}