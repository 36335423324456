import { createSlice } from '@reduxjs/toolkit'
import { getWebConfigurationACTION, getTableWithServiceDetailsACTION, getPOSuserGoogleReviewURL } from '../actions/webConfig.Actions'
import { StorageKeys, setCookieStoreObject } from '../../localStorage';
const INITIAL_STATE = {
    webConfiguration: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    tableWithServiceDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    menuSelect: {
        id: null,
        name: null,
        categories: null,
        services: null
    },
    restartOrder: false,

    POSUserGoogleReviewURLdata: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    }
};

const webConfigReducer = createSlice({
    name: 'WebConfig',
    initialState: INITIAL_STATE,
    reducers: {
        SETMENU(state, action) {
            state.menuSelect = action.payload;
        },
        CHANNGE_MENUTYPE_RESTARTORDER(state, action) {
            //console.log("CHANNGE_MENUTYPE_RESTARTORDER action", action)
            state.restartOrder = action.payload;
        },
        ResetTableWithServiceDetails(state, action) {
            state.tableWithServiceDetails = INITIAL_STATE.tableWithServiceDetails;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getWebConfigurationACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getWebConfigurationACTION.fulfilled, (state, action) => {
            //console.log("getWebConfigurationACTION", action);
            state.webConfiguration.data = action.payload.data;
            if (action.meta.arg.currentMenutype && action.payload.data.menuTypes && action.payload.data.menuTypes.length) {
                var currentMenutype = action.payload.data.menuTypes.filter(x => x.slug === action.meta.arg.currentMenutype);
                state.menuSelect = currentMenutype[0];
            } else {
                state.menuSelect = action.payload.data.menuTypes.length > 0 ? action.payload.data.menuTypes[0] : state.menuSelect;
            }



            state.webConfiguration.apiMsg.status = action.meta.requestStatus;
            state.webConfiguration.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getWebConfigurationACTION.pending, (state, action) => {
                //console.log("getWebConfigurationACTION pending", action);
                state.webConfiguration.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getWebConfigurationACTION.rejected, (state, action) => {
                //console.log("getWebConfigurationACTION rejected", action);
                state.webConfiguration.apiMsg.message = action.error.message;
                state.webConfiguration.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getWebConfigurationACTION end -------------------------
            //------------------------------- getTableWithServiceDetailsACTION start -------------------------
            // eslint-disable-next-line
            builder.addCase(getTableWithServiceDetailsACTION.fulfilled, (state, action) => {
                //console.log("getTableWithServiceDetailsACTION", action);
                state.tableWithServiceDetails.data = action.payload.data;
                state.tableWithServiceDetails.apiMsg.status = action.meta.requestStatus;
                state.tableWithServiceDetails.apiMsg.message = action.payload.message.message;
                setCookieStoreObject(StorageKeys.ServiceDetails, state.tableWithServiceDetails.data);
            }),
            builder.addCase(getTableWithServiceDetailsACTION.pending, (state, action) => {
                //console.log("getTableWithServiceDetailsACTION pending", action);
                state.tableWithServiceDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getTableWithServiceDetailsACTION.rejected, (state, action) => {
                //console.log("getTableWithServiceDetailsACTION rejected", action);
                state.tableWithServiceDetails.apiMsg.message = action.error.message;
                state.tableWithServiceDetails.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getTableWithServiceDetailsACTION end -------------------------


        builder.addCase(getPOSuserGoogleReviewURL.fulfilled, (state, action) => {
            state.POSUserGoogleReviewURLdata.data = action.payload.data;
            state.POSUserGoogleReviewURLdata.apiMsg.status = action.meta.requestStatus;
            state.POSUserGoogleReviewURLdata.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPOSuserGoogleReviewURL.pending, (state, action) => {
            state.POSUserGoogleReviewURLdata.data = null;
            state.POSUserGoogleReviewURLdata.apiMsg.message = action.meta.requestStatus;;
            state.POSUserGoogleReviewURLdata.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPOSuserGoogleReviewURL.rejected, (state, action) => {
            state.POSUserGoogleReviewURLdata.apiMsg.message = action.error.message;
            state.POSUserGoogleReviewURLdata.apiMsg.status = action.meta.requestStatus;
        });

    }
})

export const { SETMENU, CHANNGE_MENUTYPE_RESTARTORDER, ResetTableWithServiceDetails } = webConfigReducer.actions;
export default webConfigReducer.reducer;