import React from "react";
import { Oval, ThreeDots } from 'react-loader-spinner';
import {
    Container, Navbar,
    Row, Col
} from 'react-bootstrap';

export const PlaceholderLoader = () => {
    return (
        <>
            <Loader />
            <div className="pb-4">
                <header className='header-placeholder'>

                    <Container className='app-container'>
                        <Row className='app-header-row'>
                            <Col xl="3" lg="3" md="2" sm="2" xs="4">
                                <div className='mb-2 shimmerBG logo-img-placeholder'>


                                </div>
                            </Col>
                            <Col md="6" className="max-w-469">
                                <div className="shimmerBG content-line-placeholder rounded-pill" style={{ height: "50px" }}>
                                    <div className="find-btn-placeholder"></div>
                                </div>

                            </Col>
                            <Col>
                                <div className="shimmerBG content-line-placeholder acc-menu-placeholder"></div>

                            </Col>



                        </Row>
                    </Container>
                </header>
                <Navbar className="menubar-placeholder shimmerBG">

                </Navbar>
                <Navbar className="services-placeholder shimmerBG">

                </Navbar>
                <Container fluid className="mt-4 mb-4">
                    <Row>
                        <Col md="3" className="">
                            <div className="p-4 h-199 shimmerBG">

                            </div>
                        </Col>
                        <Col md="6">
                            <div className="p-4 h-199 shimmerBG">

                            </div>
                        </Col>
                        <Col md="3">
                            <div className="p-4 h-199 shimmerBG">

                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md="12" className="mb-3">
                            <div className="shimmerBG content-line-placeholder" style={{ height: "50px" }}></div>
                        </Col>
                        {
                            [1, 2, 3, 4].map(() => {
                                return (
                                    <Col md="3" className="">
                                        <div style={{ height: "310px" }}>
                                            <figure>

                                                <img className="placeholder-img" role="presentation" src={require("../images/placeholder-28x15.png")} alt="img-placeholder" />
                                                <div className="shimmerBG" style={{ height: "145px" }}>

                                                </div>
                                            </figure>
                                        </div>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Container>
            </div>
        </>
    );
};


export const Loader = () => {
    return (
        <>
            <div className="route-loader">
                <Oval
                    height={80}
                    width={80}
                    color="#000"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#004a6e"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />

            </div>

        </>
    );
};

export const ButtonLoader = () => {
    return (
        <>
            <div className="btn-loader">
                <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#000"
                    strokeWidth={4}
                    strokeWidthSecondary={4}

                />

            </div>

        </>
    );
};

export const ThreeDotsLoader = () => {

    return (
        <ThreeDots
            height="50"
            width="50"
            radius="5"
            color="#ffc107"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName="loader-colo"
            visible={true}
        />
    )
};

