import { Cookies } from 'react-cookie'
export const StorageKeys = {
    ServiceDetails: "ServiceDetails",
    token: "token",
    cartID: "cartID"
}
export const setLocalStore = (KEY, VAlUE) => {

    try {
        localStorage.setItem(KEY, VAlUE);
        return 201
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const setCookieStore = (KEY, VAlUE) => {

    try {
        const cookies = new Cookies()
        cookies.set(KEY, VAlUE, { path: '/', maxAge: 86400 })
        return 201
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const setLocalStoreObject = (KEY, VAlUE) => {

    try {
        return localStorage.setItem(KEY, JSON.stringify(VAlUE));
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const setCookieStoreObject = (KEY, VAlUE) => {

    try {
        const cookies = new Cookies()
        cookies.set(KEY, JSON.stringify(VAlUE), { path: '/', maxAge: 86400 })
        return;
    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const getLocalStore = (KEY) => {

    try {
        if (localStorage.getItem(KEY)) {
            return localStorage.getItem(KEY);
        }
        else {
            const cookies = new Cookies()
            return cookies.get(KEY);
        }

    } catch (error) {
        //console.log('Error: ',error)
        return 500
    }

}
export const getLocalStoreObject = (KEY) => {

    try {
        if (localStorage.getItem(KEY)) {
            return JSON.parse(localStorage.getItem(KEY));
        }
        else {
            const cookies = new Cookies()
            return cookies.get(KEY);
        }

    } catch (error) {
        // console.log('Error: ',error)
        return 500
    }

}
export const clearLocalStore = (allclear, key) => {
    const cookies = new Cookies();
    if (allclear) {

        localStorage.clear();
        cookies.remove(StorageKeys.ServiceDetails);
        cookies.remove(StorageKeys.token);
        cookies.remove(StorageKeys.cartID);
    } else {
        // To clear a specific item
        console.log("clear value of "+key)
        cookies.remove(key);
        localStorage.removeItem(key);
    }
}
