import { createSlice } from '@reduxjs/toolkit'
import {
    getMenuTypeServicesACTION,
    isValidateServiceACTION,
    startOrderACTION,
    restartOrderACTION
} from '../actions/menuTypeService.Actions'
const INITIAL_STATE = {
    menuTypeService: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    validateService: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    menuConfigService: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    menuConfigServiceReset: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    openChooseServicePopup:false

};

const menuTypeServiceReducer = createSlice({
    name: 'MenuTypeService',
    initialState: INITIAL_STATE,
    reducers: {
        SELECT_SERVICE(state, action) {
            state.menuConfigService.data = action.payload;
        },
        RESETMENUCONFIGSERVICE(state, action) {
            state.menuConfigService = INITIAL_STATE.menuConfigService;
        },
        RESETVALIDATESERVICE(state, action) {
            state.validateService = INITIAL_STATE.validateService;
        },
        TriggerChooseServicePopup(state, action) {
            state.openChooseServicePopup = action.payload;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getMenuTypeServicesACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getMenuTypeServicesACTION.fulfilled, (state, action) => {
            // console.log("getMenuTypeServicesACTION",  action);
            state.menuTypeService.data = action.payload.data;
            state.menuTypeService.apiMsg.status = action.meta.requestStatus;
            state.menuTypeService.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getMenuTypeServicesACTION.pending, (state, action) => {
                //console.log("getMenuTypeServicesACTION pending", action);
                state.menuTypeService.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getMenuTypeServicesACTION.rejected, (state, action) => {
                //console.log("getMenuTypeServicesACTION rejected", action);
                state.menuTypeService.apiMsg.message = action.error.message;
                state.menuTypeService.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getMenuTypeServicesACTION end -------------------------
            //------------------------------- isValidateServiceACTION start -------------------------
            builder.addCase(isValidateServiceACTION.fulfilled, (state, action) => {
                // console.log("isValidateServiceACTION", action);
                state.validateService.data = action.payload;
                state.validateService.apiMsg.status = action.meta.requestStatus;
                state.validateService.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(isValidateServiceACTION.pending, (state, action) => {
                // console.log("isValidateServiceACTION pending", action);
                state.validateService.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(isValidateServiceACTION.rejected, (state, action) => {
                // console.log("isValidateServiceACTION rejected", action);
                state.validateService.apiMsg.message = action.error.message;
                state.validateService.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- isValidateServiceACTION end -------------------------
            //------------------------------- startOrderACTION start -------------------------
            builder.addCase(startOrderACTION.fulfilled, (state, action) => {
                console.log("startOrderACTION", action.payload.data);
                state.menuConfigService.data = action.payload.data;
                state.menuConfigService.apiMsg.status = action.meta.requestStatus;
                state.menuConfigService.apiMsg.message = action.payload.message.message;
                state.openChooseServicePopup = false;

            }),
            builder.addCase(startOrderACTION.pending, (state, action) => {
                // console.log("startOrderACTION pending", action);
                state.menuConfigService.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(startOrderACTION.rejected, (state, action) => {
                // console.log("startOrderACTION rejected", action);
                state.menuConfigService.apiMsg.message = action.error.message;
                state.menuConfigService.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- startOrderACTION end -------------------------
            //------------------------------- restartOrderACTION start -------------------------
            builder.addCase(restartOrderACTION.fulfilled, (state, action) => {
                 console.log("restartOrderACTION", action);
                state.menuConfigServiceReset.data = action.payload;
                state.menuConfigServiceReset.apiMsg.status = action.meta.requestStatus;
                state.menuConfigServiceReset.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(restartOrderACTION.pending, (state, action) => {
                // console.log("restartOrderACTION pending", action);
                state.menuConfigServiceReset.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(restartOrderACTION.rejected, (state, action) => {
                // console.log("restartOrderACTION rejected", action);
                state.menuConfigServiceReset.apiMsg.message = action.error.message;
                state.menuConfigServiceReset.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- restartOrderACTION end -------------------------
    }
})

export const { SELECT_SERVICE, RESETMENUCONFIGSERVICE, RESETVALIDATESERVICE,TriggerChooseServicePopup } = menuTypeServiceReducer.actions;
export default menuTypeServiceReducer.reducer;