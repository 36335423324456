import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../API';
import * as toast from '../../components/Toast'

export const addToCartACTION = createAsyncThunk(
    'Order/addtocart',
    async (data) => {

        return API.apiCall("/restaurant/web/addtocart", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message, { autoClose: 3000 })
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getCartDetailsACTION = createAsyncThunk(
    'Order/getCartDetails',
    async (data) => {

        return API.apiCall("/restaurant/web/getcartdetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        //toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const removeCartItemACTION = createAsyncThunk(
    'Order/removeCartItem',
    async (data) => {

        return API.apiCall("/restaurant/web/removecartitem", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const placeOrderACTION = createAsyncThunk(
    'Order/placeOrder',
    async (data) => {

        return API.apiCall("/restaurant/web/placeorder", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getOrderDetailACTION = createAsyncThunk(
    'Order/getOrderDetail',
    async (data) => {

        return API.apiCall("/restaurant/web/getorderdetail", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getAllUserOrderACTION = createAsyncThunk(
    'Order/getAllUserOrder',
    async (data) => {

        return API.apiCall("/restaurant/web/getalluserorders", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        //toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const paymentConfirmationACTION = createAsyncThunk(
    'Order/paymentConfirmation',
    async (data) => {

        return API.apiCall("/restaurant/web/paymentconfirmation", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        //toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);
export const applyPromocodeACTION = createAsyncThunk(
    'Order/applypromocode',
    async (data) => {

        return API.apiCall("/restaurant/web/applypromocode", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);
export const removePromocodeACTION = createAsyncThunk(
    'Order/removepromocode',
    async (data) => {

        return API.apiCall("/restaurant/web/removepromocode", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);


export const repeatOrderAction = createAsyncThunk(
    'Order/repeatOrder',
    async (data) => {

        return API.apiCall("/restaurant/web/repeatorder", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.code)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);