import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../API';
import * as toast from '../../components/Toast'

export const getWebConfigurationACTION = createAsyncThunk(
    'WebConfig/getWebConfiguration',
    async (data) => {

        return API.apiCall("/restaurant/web/getwebconfiguration", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getTableWithServiceDetailsACTION = createAsyncThunk(
    'WebConfig/getTableWithServiceDetails',
    async (data) => {
        //console.log("data getTableWithServiceDetailsACTION",data)
        return API.apiCall("/restaurant/web/gettablewithservicedetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getPOSuserGoogleReviewURL = createAsyncThunk(
    'Authentication/getPOSuserGoogleReviewURL',
    async (data) => {
        return API.apiCall("/restaurant/web/user/getposusergooglereviewurl", "POST", data, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)

                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error
                })
            })
    }
);