import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../API';
import * as toast from '../../components/Toast'

export const getProductsACTION = createAsyncThunk(
    'Products/getProducts',
    async (data) => {

        return API.apiCall("/restaurant/web/getproducts", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const getProductDetailsACTION = createAsyncThunk(
    'Products/getProductDetails',
    async (data) => {

        return API.apiCall("/restaurant/web/getproductdetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);