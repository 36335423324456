import { ReducerStateContant } from "../constants/general-const";
import _ from "lodash";
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
function StateNotInitiated(stateObject) {
    if (stateObject.apiMsg.status===null) {
        return true;
    }
    else {
        return false;
    }
};
function StateCompleted(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.fulfilled) {
        return true;
    }
    else {
        return false;
    }
};

function StatePending(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.pending) {
        return true;
    }
    else {
        return false;
    }
};

function StateRejected(stateObject) {
    if (stateObject.apiMsg.status === ReducerStateContant.rejected) {
        return true;
    }
    else {
        return false;
    }
};

function StateHasRecords(stateObject) {
    return stateObject && stateObject.data && stateObject.data.records.length;
};

function StateHasRecordslength(stateObject) {
    return stateObject && stateObject.data && stateObject.data.records.length > 0;
};
function StateHasData(stateObject) {
    return stateObject && stateObject.data;
};

function StateHasDataLenght(stateObject) {
    return stateObject && stateObject.data && stateObject.data.length;
};

const GetLookupValue = (stateObject, lookupType) => {
    let values = []
    if (stateObject && stateObject.data && stateObject.data.length) {
        var lookupData = _.find(stateObject.data, { lookupType: lookupType });
        values = lookupData ? lookupData.data : [];
    }
    return values || [];
};
function GetCookie(cookieName) {
    let cookie = cookies.get(cookieName)
    if (cookie) {
        return cookie
    }
    return false
    // let name = username + "=";
    // let spli = document.cookie.split(';');
    // for (var j = 0; j < spli.length; j++) {
    //     let char = spli[j];
    //     while (char.charAt(0) == ' ') {
    //         char = char.substring(1);
    //     }
    //     if (char.indexOf(name) == 0) {
    //         return char.substring(name.length, char.length);
    //     }
    // }
    // return "";
}
function pathAuthCheck(path) { return path === '/login' || path === '/signup' || path === '/validate' || path === '/forgotPassword' || path === '/checkout' || path === "/order/success" || path.search('/myaccount') > -1 }

function GetSearchParams(searchParam) {

    var searchFilters = [];
    searchParam.forEach(((value, key) => {
        searchFilters.push({ key: key, value: value });
    }));
    //console.log("menuSelection", searchFilters);

    return searchFilters;
}

export {
    StateNotInitiated,
    StateCompleted,
    StateRejected,
    StateHasRecords,
    StateHasRecordslength,
    StateHasData,
    StateHasDataLenght,
    GetLookupValue,
    StatePending,
    GetCookie,
    pathAuthCheck,
    GetSearchParams
};